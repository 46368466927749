<template>
  <router-view data-app />
</template>



<script>
export default {
  name: "App",
};
</script>