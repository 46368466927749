import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from "./router";
import VueI18n from 'vue-i18n'
import FlashMessage from "@smartweb/vue-flash-message";
import store from "./store";
import "./plugins/vuetify-mask.js"
import {VueReCaptcha} from 'vue-recaptcha-v3'
import {uuid} from 'vue-uuid';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

Vue.config.devtools = process.env.VUE_APP_DEV;
Vue.config.debug = process.env.VUE_APP_DEV;
Vue.config.silent = !process.env.VUE_APP_DEV;
Vue.config.productionTip = false;

Vue.use(uuid);
Vue.use(VueI18n)
Vue.use(VueTelInputVuetify, {
    vuetify,
});
let campaignName = location.pathname.split('/')[1];
if (!campaignName) {
    campaignName = process.env.VUE_APP_DEFAULT_CAMPAIGNNAME;
} else if (campaignName.toLowerCase() == "home") {
    campaignName = process.env.VUE_APP_DEFAULT_CAMPAIGNNAME
} else if (campaignName.toLowerCase() == "catalog") {
    campaignName = process.env.VUE_APP_DEFAULT_CAMPAIGNNAME
} else if (campaignName.toLowerCase() == "redeem") {
    campaignName = process.env.VUE_APP_DEFAULT_CAMPAIGNNAME
} else if (campaignName.toLowerCase() == "redeemconfirm") {
    campaignName = process.env.VUE_APP_DEFAULT_CAMPAIGNNAME
}

//console.log(campaignName);
const campaignKey = location.host.replace("www.", "") + "_" + campaignName;

const appConfigUrl = process.env.VUE_APP_CONFIG_FILE_PATH
    + campaignKey
    + "/" + process.env.VUE_APP_CONFIG_FILE_NAME;

const appConfigUrlDefault = process.env.VUE_APP_CONFIG_FILE_PATH
    + "default_config/" + process.env.VUE_APP_CONFIG_FILE_NAME;

Vue.prototype.$campaignKey = campaignKey;
Vue.prototype.$campaignName = campaignName;
// Load all locales and remember context
function loadMessages() {
    const context = require.context("./locales", true, /[a-z0-9-_]+\.json$/i);

    const messages = context
        .keys()
        .map((key) => ({key, locale: key.match(/[a-z0-9-_]+/i)[0]}))
        .reduce(
            (messages, {key, locale}) => ({
                ...messages,
                [locale]: context(key),
            }),
            {}
        );

    return {context, messages};
}

const {context, messages} = loadMessages();

// VueI18n instance
const i18n = new VueI18n({
    locale: "es-ES",
    messages,
});

Vue.use(VueAxios, axios)
Vue.use(FlashMessage, {
    time: process.env.VUE_APP_FLASHMESSAGE_TIME,
    strategy: "multiple"
})

// Recaptcha v3
Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
    loaderOptions: {
        autoHideBadge: true
    }
});

axios.get(appConfigUrl).then((response) => {
    Vue.prototype.$appConfig = response.data;
    i18n.locale = response.data.defaultCulture;

    //No se estaba asignando  el valor al atributo data-cbid porque el index.html ya estaba cargado
    
    /*const cookiebotScript = document.getElementById("Cookiebot");
    if (cookiebotScript && typeof Cookiebot !== 'undefined' && response.data.cookiebotId) {
        cookiebotScript.setAttribute("data-cbid", response.data.cookiebotId);
        // eslint-disable-next-line no-undef
        if (!Cookiebot.hasResponse) {
            // eslint-disable-next-line no-undef
            Cookiebot.show();
        }
    } else {
        console.error("No se encontró el script con el ID 'Cookiebot' o no hay configurado ningún ID de Cookiebot en el archivo de configuración.");
    }
*/

    const cookiebotId = response.data.cookiebotId;
    if (cookiebotId) {
        const cookiebotScript = document.createElement("script");
        cookiebotScript.id = "Cookiebot";
        cookiebotScript.src = "https://consent.cookiebot.com/uc.js";
        cookiebotScript.type = "text/javascript";
        cookiebotScript.setAttribute("data-cbid", cookiebotId);
        cookiebotScript.setAttribute("data-blockingmode", "auto");
        document.head.appendChild(cookiebotScript);
    } else {
        console.error("No hay configurado ningún ID de Cookiebot en el archivo de configuración.");
    }

    new Vue({
        data: {
            redeemConfirming: true,
            redeemConfirmationMessage: ""
        },
        i18n,
        vuetify,
        router,
        axios,
        FlashMessage,
        VueReCaptcha,
        store,
        render: h => h(App)
    }).$mount('#app');
}).catch(() => {
    axios.get(appConfigUrlDefault).then((response) => {
        Vue.prototype.$appConfig = response.data;
        i18n.locale = response.data.defaultCulture;
        new Vue({
            data: {
                redeemConfirming: true,
                redeemConfirmationMessage: ""
            },
            i18n,
            vuetify,
            router,
            axios,
            FlashMessage,
            VueReCaptcha,
            store,
            render: h => h(App)
        }).$mount('#app');
    });
});


// Hot updates
if (module.hot) {
    module.hot.accept(context.id, () => {
        const {messages: newMessages} = loadMessages();

        Object.keys(newMessages)
            .filter((locale) => messages[locale] !== newMessages[locale])
            .forEach((locale) => {
                messages[locale] = newMessages[locale];
                i18n.setLocaleMessage(locale, messages[locale]);
            });
    });
}

