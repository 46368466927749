import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

//import appConfigDefault from "../../configuration.json";


Vue.use(Vuetify);
export default new Vuetify({
    icons: {
        iconfont: "mdi",
    },
    /* theme: {
        dark: appConfigDefault.dark,
        themes: appConfigDefault.themes,
    } */
}
);

