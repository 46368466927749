import Vue from "vue";
import Router from "vue-router";
import VueAppInsights from 'vue-application-insights'

const router = new Router({
    mode: "history",
    linkActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()

});
Vue.use(Router);

Vue.use(VueAppInsights, {
    baseName: 'ctc-landing', // prefix to track route changes as page views with AppInsights
    id: process.env.VUE_APP_AI_INSTRUMENTATION_KEY,
    appInsightsConfig: {
        enableAutoRouteTracking: true
    }
})

Vue.prototype.$appInsights.trackEvent({ name: "AppLoad" });

var base64 = require('uuid-base64');
var uid = base64.decode(Vue.prototype.$appInsights.context.user.id);

Vue.prototype.$uuid = uid;
export default router;

function configRoutes() {
    return [
        {
            path: "/",
            name: "main-root",
            component: () => import("@/MainView"),
            redirect: "/home",
            children: [

                {
                    path: "home",
                    name: "home",
                    component: () => import("@/components/Home"),
                    meta: {
                        requiresAuth: false,
                    },

                },
                {
                    path: "catalog",
                    name: "catalog",
                    component: () => import("@/components/Catalog"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "redeem",
                    name: "redeem",
                    component: () => import("@/components/Redeem"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "redeemconfirm",
                    name: "redeemconfirm",
                    component: () => import("@/components/RedeemConfirm"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "redeemconfirmwarning",
                    name: "redeemconfirmwarning",
                    component: () => import("@/components/RedeemConfirmWarning"),
                    meta: {
                        requiresAuth: false,
                    },
                },
            ]
        },
        {
            path: "/:campaign",
            name: "mainParam",
            component: () => import("@/MainView"),
            redirect: '/:campaign/home/:code?',
            children: [

                {
                    path: "/:campaign/home/:code?",
                    name: "homeParam",
                    component: () => import("@/components/Home"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/:campaign/catalog",
                    name: "catalogParam",
                    component: () => import("@/components/Catalog"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/:campaign/redeem",
                    name: "redeemParam",
                    component: () => import("@/components/Redeem"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/:campaign/redeemconfirm",
                    name: "redeemconfirmParam",
                    component: () => import("@/components/RedeemConfirm"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/:campaign/redeemconfirmwarning",
                    name: "redeemconfirmwarningParam",
                    component: () => import("@/components/RedeemConfirmWarning"),
                    meta: {
                        requiresAuth: false,
                    },
                },
            ]
        },



    ];

}


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {

    return originalPush.call(this, location).catch(err => err)
}