import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        code: "",
        catalogToken: "",
        selectedProduct: {
            productCode: "",
            orderProductCode: "",
            clientProductCode: "",
            productSetId: "",
            name: "",
            description: "",
            familyId: "",
            stock: 0,
            points: 0,
            imageUrl: "",
            isVoucher: null,
        },

    },
    getters: {},
    mutations: {
        updateCode(state, payload) {
            state.code = payload
        },
        updateCatalogToken(state, payload) {
            state.catalogToken = payload
        },
        updateSelectedProduct(state, payload) {
            state.selectedProduct = payload
        },
    },
    actions: {
        updateCode(context, payload) {

            context.commit("updateCode", payload);

        },
        updateCatalogToken(context, payload) {

            context.commit("updateCatalogToken", payload);

        },
        updateSelectedProduct(context, payload) {

            context.commit("updateSelectedProduct", payload);

        }
    }
});